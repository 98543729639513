<!-- resources/js/MyComponent.vue -->
<template>
    <div class="bg-white block w-full md:flex h-full relative mx-auto">
        <div class="relative h-full max-w-[100%]" style="width: 460px;">
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative h-full w-full">
                    <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] gap-x-2 sm:block border-r-2 border-gray-200">
                        <div class="text-md font-bold pb-2 border-b border-[#DEE8F9]"><i class="w-4 h-4 bg-contain bg-center bg-[url(/images/icons/ai-paragraph-generator.webp)] inline-flex"></i><span class="text-lg ml-1">Plot Generator</span></div>
                        <label for="story" class="flex text-sm font-semibold mt-3">Enter your Story Idea</label>
                        <textarea id="story" rows="6" v-model="rawtext" class="w-full border-gray-200 rounded-md mt-1" :class="{ 'border-red-500': isTextareaEmpty }" @input="isTextareaEmpty = rawtext.trim() === ''" required></textarea>
                        <div v-if="isTextareaEmpty" class="text-xs text-red-500"> {{ errorMessage }} </div>

                        <p class="text-xs text-right text-gray-700" :class="rawtext.length > 250 ? 'text-red-400':''">{{rawtext.length}}/250</p>
                        <label for="language" class="text-sm font-semibold mt-3">Language</label>
                        <select id="language" class="mt-1 shadow appearance-none border-gray-200 rounded-md rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Select Language">
                            <option class="english">English</option>
                        </select>
                        <div class="w-full py-2">
                            <div id="cf-container"></div>
                        </div>
                        <button @click="doParaphrase()" :disabled="loading ||  token == null" class="flex flex-wrap mt-4 ml-0 disable:bg-[#8b5cf680] bg-[#8730cb] hover:bg-[#610aa5] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline gap-x-2" type="button">
                            <svg v-if="loading" class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Generate Plot
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1 h-auto bg-white border-l">
            <QuillEditor theme="snow" v-model:content="editortext" contentType="text" toolbar="minimal" class="h-auto"/>
            <ActionButtonsComponent :text-to-copy="editortext" :editortext="editortext"/>
            <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
import ErrorPopupComponent from "@/Components/ErrorPopupComponent.vue";
export default {
    components:{
        QuillEditor,
        ActionButtonsComponent,
        ErrorPopupComponent
    },
    props:{
        translations:Object,
    },
    data(){
        return {
            sitekey:'0x4AAAAAAAQWyAxhVrxfHtpm',
            token: null,
            rawtext:'',
            editortext:null,
            loading:false,
            isTextareaEmpty: false,
            errorMessage: "This field is required.",
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",

        };
    },
    mounted(){
        this.renderCf();
    },
    beforeMount() {
        this.cfInit();
    },
    methods:{
        cfInit(){
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        renderCf(){
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function(token) {
                        _this.verify(token);
                    },
                });
            };
        },
        verify(token){
            this.token = token;
        },
        doParaphrase(){
            this.loading = true;
            axios.post("/tool/plotGenerator", {
                capcha_token: this.token,
                rawtext:this.rawtext,
                lang:'english',
                mode:'creative',
            })
                .then(response=>{
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    this.loading =false;
                    turnstile.reset('#cf-container');

                    const lettersOnly = /^[a-zA-Z\s]+$/;

                    if (e.response && e.response.status === 422) {
                        this.serverErrorMessage = e.response.data.message;
                        this.yourErrorHeaderVariable = "Error 422";
                    }
                    else if (this.rawtext.trim() === '') {
                        this.errorMessage = "Input Text Required"; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Input Text Required.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else if (!lettersOnly.test(this.rawtext)) {
                        this.errorMessage = "Only letters are allowed."; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Only letters are allowed.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else if (this.rawtext.trim().length < 6) {
                        this.errorMessage = "Content is too short, Please add atleast 6 character."; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Content is too short, Please add atleast 6 character.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else {
                        this.serverErrorMessage = "Something went wrong. Please try again and reload.";
                        this.yourErrorHeaderVariable = "Fetal Error";
                    }

                    // Show the error popup for other errors
                    this.$refs.errorPopup.showError = true;
                });
        }
    }
};

</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
</style>
